import * as React from "react";
import { AwarenessPlacement } from "@shared-ui/retail-credit-card-awareness-placement";
import { useExperiment } from "@shared-ui/experiment-context";
import { ComponentProps } from "typings/flexFramework/FlexComponent";

export const CreditCardAwarenessPlacement: React.FC<ComponentProps> = (props) => {
  const { templateComponent } = props;
  const { exposure } = useExperiment("EG_Cobrand_Credit_Card_Web_Switch");

  if (!templateComponent || exposure.bucket === 0) {
    return null;
  }

  return <AwarenessPlacement inputs={{}} />;
};

export default CreditCardAwarenessPlacement;
